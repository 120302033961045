import * as utils from './common.util';

export const readExcelFile = (teamList, setDataSet) => {
  let dataSet = [];
  if (teamList) {
    let x = teamList;
    let i;
    for (i in x) {
      dataSet.push({
        name: x[i].operating_asset_first_name,
        asset_type: x[i].asset_type_name,
        phone: x[i].operating_asset_phone_number,
        email: x[i].operating_asset_email_id,
        workforce: x[i].workforce_name,
        account_name: x[i].account_name,
        asset_created_datetime: utils.timelineDateFormatShort(
          x[i].asset_created_datetime
        ),
        asset_last_seen_datetime: !!x[i].asset_last_seen_datetime
          ? utils.timelineDateFormatShort(x[i].asset_last_seen_datetime)
          : '',
        asset_pool_entry_datetime: utils.timelineDateFormatShort(
          x[i].asset_pool_entry_datetime
        ),
        asset_activated_enabled: x[i].asset_activated_enabled,
        asset_flag_suspended:
          x[i].asset_flag_suspended === 0 ? 'Active' : 'Suspended',
        asset_flag_inactive: x[i].asset_flag_inactive,
        asset_last_location_address: x[i].asset_last_location_address,
        organization_name: x[i].organization_name,
        reporting: x[i].manager_operating_asset_first_name,
      });
    }
    setDataSet(dataSet);
  } else {
    return false;
  }
};
