// pv == productVariant
// cm == contractMonths
const calculateTcv = params => {
  const { productValue, contractMonths } = params;
  return (parseFloat(productValue) * parseFloat(contractMonths)).toString();
};

const calculateAov = params => {
  const { productValue } = params;
  return (parseFloat(productValue) * 12).toString();
};

const LAST_DATE_OF_CLOSURE = '2023-03-31';

const calcStub = {
  150229: {
    name: 'New Opportunity - SME Partner',
    contractMonths: {
      field_id: 77666,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 218715,
      field_name: 'Product Selection',
    },
    77666: {
      field_id: 77666,
      field_name: 'Contract Term in Months',
      dependentFields: {
        77667: 'TCV (INR Mn)',
        77668: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          77667: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          77668: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    218715: {
      field_id: 218715,
      field_name: 'Product Selection',
      dependentFields: {
        77667: 'TCV (INR Mn)',
        77668: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          77667: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          77668: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    77667: {
      field_id: 77667,
      field_name: 'TCV (INR)',
      calculateValue: calculateTcv,
    },
    77668: {
      field_id: 77668,
      field_name: 'AOV (INR)',
      calculateValue: calculateAov,
    },
  },
  149058: {
    name: 'Regular Enterprise Opportunity',
    contractMonths: {
      field_id: 29896,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 218716,
      field_name: 'Product Selection',
    },
    218716: {
      field_id: 218716,
      field_name: 'Product Selection',
      dependentFields: {
        29898: 'TCV (INR Mn)',
        29899: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          29898: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          29899: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    29896: {
      field_id: 29896,
      field_name: 'Contract Term in Months',
      dependentFields: {
        29898: 'TCV (INR Mn)',
        29899: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          29898: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          29899: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    29898: {
      field_id: 29898,
      field_name: 'TCV (INR Mn)',
      calculateValue: calculateTcv,
    },
    29899: {
      field_id: 29899,
      field_name: 'AOV (INR Mn)',
      calculateValue: calculateAov,
    },
  },
  149752: {
    name: 'New Opportunity - Tender / RFP',
    contractMonths: {
      field_id: 56128,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 218717,
      field_name: 'Product Selection',
    },
    56128: {
      field_id: 56128,
      field_name: 'Contract Term in Months',
      dependentFields: {
        56130: 'TCV (INR Mn)',
        56131: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          56130: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          56131: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    218717: {
      field_id: 218717,
      field_name: 'Product Selection',
      dependentFields: {
        56130: 'TCV (INR Mn)',
        56131: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          56130: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          56131: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    56130: {
      field_id: 56130,
      field_name: 'TCV (INR Mn)',
      calculateValue: calculateTcv,
    },
    56131: {
      field_id: 56131,
      field_name: 'AOV (INR Mn)',
      calculateValue: calculateAov,
    },
  },
  149818: {
    name: 'New Opportunity - Renewal Opportunity',
    contractMonths: {
      field_id: 56202,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 218718,
      field_name: 'Product Selection',
    },
    56202: {
      field_id: 56202,
      field_name: 'Contract Term in Months',
      dependentFields: {
        56204: 'TCV (INR Mn)',
        56205: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          56204: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          56205: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    218718: {
      field_id: 218718,
      field_name: 'Product Selection',
      dependentFields: {
        56204: 'TCV (INR Mn)',
        56205: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          56204: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          56205: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    56204: {
      field_id: 56204,
      field_name: 'TCV (INR Mn)',
      calculateValue: calculateTcv,
    },
    56205: {
      field_id: 56205,
      field_name: 'AOV (INR Mn)',
      calculateValue: calculateAov,
    },
  },
  151727: {
    name: 'New Opportunity - Modification Opportunity',
    contractMonths: {
      field_id: 215443,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 218719,
      field_name: 'Product Selection',
    },
    215443: {
      field_id: 215443,
      field_name: 'Contract Term in Months',
      dependentFields: {
        215445: 'TCV (INR Mn)',
        215446: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          215445: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          215446: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    218719: {
      field_id: 218719,
      field_name: 'Product Selection',
      dependentFields: {
        215445: 'TCV (INR Mn)',
        215446: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          215445: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          215446: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    215445: {
      field_id: 215445,
      field_name: 'TCV (INR Mn)',
      calculateValue: calculateTcv,
    },
    215446: {
      field_id: 215446,
      field_name: 'AOV (INR Mn)',
      calculateValue: calculateAov,
    },
  },
  151728: {
    name: 'New Opportunity - Channel Opportunity',
    contractMonths: {
      field_id: 218000,
      field_name: 'Contract Term in Months',
    },
    productCart: {
      field_id: 217729,
      field_name: 'Select Product',
    },
    218000: {
      field_id: 218000,
      field_name: 'Contract Term in Months',
      dependentFields: {
        215613: 'TCV (INR Mn)',
        215614: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          215613: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          215614: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    217729: {
      field_id: 217729,
      field_name: 'Product Selection',
      dependentFields: {
        215613: 'TCV (INR Mn)',
        215614: 'AOV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_tcv = (parseInt(mrc) * parseInt(cm)).toString();
        let bulk_aov = (parseInt(mrc) * 12).toString();
        return {
          215613: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          215614: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
        };
      },
    },
    215613: {
      field_id: 215613,
      field_name: 'TCV (INR Mn)',
      calculateValue: calculateTcv,
    },
    215614: {
      field_id: 215614,
      field_name: 'AOV (INR Mn)',
      calculateValue: calculateAov,
    },
  },
  151729: {
    name: 'New Opportunity - Regular Carrier Opportunity',
    productCart: {
      field_id: 221310,
      field_name: 'Product selection',
    },
    unitMonthlyRevenue: {
      field_id: 215922,
      field_name: 'Unit Monthly Revenue',
    },
    currency: {
      field_id: 300098,
      field_name: 'Currency',
    },
    conversionRate: {
      field_id: 300099,
      field_name: 'Conversion Rate',
    },
    otc: {
      field_id: 221312,
      field_name: 'OTC (INR Mn)',
    },
    contractMonths: {
      field_id: 300115,
      field_name: 'Contract Term in Months',
    },
    annualRecurringCharges: {
      field_id: 221313,
      field_name: 'Annual Recurring Charges',
    },
    dateOfClosure: {
      field_id: 221177,
      field_name: 'Expected Date of Closure',
    },
    aov: {
      field_id: 215926,
      field_name: 'AOV (INR Mn)',
    },
    221177: {
      field_id: 221177,
      field_name: 'Expected Date of Closure',
      dependentFields: {
        221178: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          221178: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    300115: {
      field_id: 300115,
      field_name: 'Contract Term in Months',
      dependentFields: {
        215925: 'TCV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          aov,
        } = params;
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(aov || 1) * yrs).toString();
        return {
          215925: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
        };
      },
    },
    221310: {
      field_id: 221310,
      field_name: 'Product selection',
      dependentFields: {
        221313: 'Annual Recurring Charges',
        215926: 'AOV (INR Mn)',
        215925: 'TCV (INR Mn)',
        221178: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let bulk_arc = parseInt(mrc) * 12 * rate;
        let bulk_aov = parseInt(bulk_arc) + parseInt(otc);
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(bulk_aov || 1) * yrs).toString();
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(bulk_arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          221313: {
            label: 'Annual Recurring Charges',
            value: bulk_arc,
          },
          215926: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          215925: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          221178: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    300099: {
      field_id: 300099,
      field_name: 'Conversion Rate',
      dependentFields: {
        221313: 'Annual Recurring Charges',
        215926: 'AOV (INR Mn)',
        215925: 'TCV (INR Mn)',
        221178: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let bulk_arc = parseInt(mrc) * 12 * rate;
        let bulk_aov = parseInt(bulk_arc) + parseInt(otc);
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(bulk_aov || 1) * yrs).toString();
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(bulk_arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          221313: {
            label: 'Annual Recurring Charges',
            value: bulk_arc,
          },
          215926: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          215925: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          221178: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    221312: {
      field_id: 221312,
      field_name: 'OTC (INR Mn)',
      dependentFields: {
        215926: 'AOV (INR Mn)',
        221178: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_aov = parseInt(arc) + parseInt(otc);
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          215926: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          221178: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    221313: {
      /// newly added on 15th jan
      field_id: 221313,
      field_name: 'Annual Recurring Charges',
      calculateValue: params => {
        const { productValue: mrc, conversionRate } = params;
        // NOTES: Latest on 3rd dec 2020
        // ARC = Total Monthly Cart Value * 12 * the conversion rate in INR per currency
        let rate = conversionRate || 1; // INR
        rate = rate < 1 ? 1 : rate;
        return parseInt(mrc) * 12 * rate;
      },
      dependentFields: {
        215926: 'AOV (INR Mn)',
        221178: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_aov = parseInt(arc) + parseInt(otc);
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          215926: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          221178: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    215926: {
      field_id: 215926,
      field_name: 'AOV (INR Mn)',
      calculateValue: params => {
        /* const { productValue: mrc, quantity, conversionRate } = params;
          // AOV = MRC * Qty * 12* conversion rate in INR per currency
          let rate = conversionRate || 1;
          return (parseInt(mrc) * 12 * parseInt(rate)).toString(); */

        /* Notes: As per the new formula provided on 3rd dec 20 */
        const { otc, arc } = params;

        return parseInt(arc) + parseInt(otc);
      },
    },
    215925: {
      field_id: 215925,
      field_name: 'TCV (INR Mn)',
      calculateValue: params => {
        // Notes: Old before 15th Jan 21
        //  Change back again request from pallavi on 12th march
        const { contractMonths: cm, aov } = params;
        const yrs = parseInt(cm) / 12;
        return (parseInt(aov || 1) * yrs).toString();

        /* Notes: As per the new formula provided on 3rd dec 20 */
        /* const { contractMonths, aov } = params;
          return parseInt(aov) * parseInt(contractMonths); */
      },
    },
    221178: {
      field_id: 221178,
      field_name: 'IYR',
      calculateValue: params => {
        /* NOTES: 
                const { aov, dateOfClosure } = params;
                // IYR  = [ date (31st March 2021) – DOC] * [aov/365]
                // date  = [ date (31st March 2021) – DOC];
                const diffInMs =
                  new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
                const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                return ((diffInDays * parseInt(aov)) / 365).toString();
        */

        const { dateOfClosure, otc, arc } = params;

        /** Notes:
         *    As per the new formula provided on 3rd dec 20
         *    [date (31st March 2021) – DOC] * [ARC/365] + OTC
         *   (diffInDays * (parseInt(arc) / 365) + otc).toString();
         */
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return (diffInDays * (parseInt(arc) / 365) + parseInt(otc)).toString();
      },
    },
  },
  151730: {
    name: 'New Opportunity - Strategic Carrier Opportunity',
    productCart: {
      field_id: 222201,
      field_name: 'Product selection',
    },
    currency: {
      field_id: 300100,
      field_name: 'Currency',
    },
    conversionRate: {
      field_id: 300101,
      field_name: 'Conversion Rate',
    },
    otc: {
      field_id: 222185,
      field_name: 'OTC (INR Mn)',
    },
    contractMonths: {
      field_id: 215976,
      field_name: 'Contract Term in Months',
    },
    annualRecurringCharges: {
      field_id: 222184,
      field_name: 'Annual Recurring Charges',
    },
    dateOfClosure: {
      field_id: 221180,
      field_name: 'Expected Date of Closure',
    },
    aov: {
      field_id: 215979,
      field_name: 'AOV (INR Mn)',
    },
    221180: {
      field_id: 221180,
      field_name: 'Expected Date of Closure',
      dependentFields: {
        221179: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          221179: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    215976: {
      field_id: 215976,
      field_name: 'Contract Term in Months',
      dependentFields: {
        215978: 'TCV (INR Mn)',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          aov,
        } = params;
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(aov || 1) * yrs).toString();
        return {
          215978: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
        };
      },
    },
    222201: {
      field_id: 222201,
      field_name: 'Product selection',
      dependentFields: {
        222184: 'Annual Recurring Charges',
        215979: 'AOV (INR Mn)',
        215978: 'TCV (INR Mn)',
        221179: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let bulk_arc = parseInt(mrc) * 12 * rate;
        let bulk_aov = parseInt(bulk_arc) + parseInt(otc);
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(bulk_aov || 1) * yrs).toString();
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(bulk_arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          222184: {
            label: 'Annual Recurring Charges',
            value: bulk_arc,
          },
          215979: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          215978: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          221179: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    300101: {
      field_id: 300101,
      field_name: 'Conversion Rate',
      dependentFields: {
        222184: 'Annual Recurring Charges',
        215979: 'AOV (INR Mn)',
        215978: 'TCV (INR Mn)',
        221179: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
        } = params;
        let rate = conversionRate || 1;
        rate = rate < 1 ? 1 : rate;
        let bulk_arc = parseInt(mrc) * 12 * rate;
        let bulk_aov = parseInt(bulk_arc) + parseInt(otc);
        const yrs = parseInt(cm) / 12;
        let bulk_tcv = (parseInt(bulk_aov || 1) * yrs).toString();
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(bulk_arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          222184: {
            label: 'Annual Recurring Charges',
            value: bulk_arc,
          },
          215979: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          215978: {
            label: 'TCV (INR Mn)',
            value: bulk_tcv,
          },
          221179: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    222185: {
      field_id: 222185,
      field_name: 'OTC (INR Mn)',
      dependentFields: {
        215979: 'AOV (INR Mn)',
        221179: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_aov = parseInt(arc) + parseInt(otc);
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          215979: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          221179: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    222184: {
      /// newly added on 15th jan
      field_id: 222184,
      field_name: 'Annual Recurring Charges',
      calculateValue: params => {
        const { productValue: mrc, conversionRate } = params;
        // NOTES: Latest on 3rd dec 2020
        // ARC = Total Monthly Cart Value * 12 * the conversion rate in INR per currency
        let rate = conversionRate || 1; // INR
        rate = rate < 1 ? 1 : rate;
        return parseInt(mrc) * 12 * rate;
      },
      dependentFields: {
        215979: 'AOV (INR Mn)',
        221179: 'IYR',
      },
      bulkCalculateValue: params => {
        const {
          productValue: mrc,
          conversionRate,
          otc,
          contractMonths: cm,
          dateOfClosure,
          arc,
        } = params;
        let bulk_aov = parseInt(arc) + parseInt(otc);
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let bulk_iyr = (
          diffInDays * (parseInt(arc) / 365) +
          parseInt(otc)
        ).toString();
        return {
          215979: {
            label: 'AOV (INR Mn)',
            value: bulk_aov,
          },
          221179: {
            label: 'IYR',
            value: bulk_iyr,
          },
        };
      },
    },
    215979: {
      field_id: 215979,
      field_name: 'AOV (INR Mn)',
      calculateValue: params => {
        /* 
        OLDER ONE Before 15Jan2021
        const { productValue: mrc, quantity, conversionRate } = params;
        // AOV = MRC * Qty * 12* conversion rate in INR per currency
        let rate = conversionRate || 1; // INR
        return (parseInt(mrc) * 12 * parseInt(rate)).toString(); */

        /* Notes: As per the new formula provided on 3rd dec 20 */
        const { otc, arc } = params;

        return parseInt(arc) + parseInt(otc);
      },
    },
    215978: {
      field_id: 215978,
      field_name: 'TCV (INR Mn)',
      calculateValue: params => {
        // Notes: Old before 15th Jan 21
        //  Change back again request from pallavi on 12th march
        const { contractMonths: cm, aov } = params;
        const yrs = parseInt(cm) / 12;
        return (parseInt(aov || 1) * yrs).toString();

        /* Notes: As per the new formula provided on 3rd dec 20 */
        /* const { contractMonths, aov } = params;
        return parseInt(aov) * parseInt(contractMonths); */
      },
    },
    221179: {
      field_id: 221179,
      field_name: 'IYR',
      calculateValue: params => {
        /* Notes: Old before 15th Jan 21
        const { aov, dateOfClosure } = params;
        // IYR  = [ date (31st March 2021) – DOC] * [aov/365]
        // date  = [ date (31st March 2021) – DOC];
        const diffInMs =
          new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return ((diffInDays * parseInt(aov)) / 365).toString();
         */

        const { dateOfClosure, otc, arc } = params;

        /** Notes:
         *    As per the new formula provided on 3rd dec 20
         *    [date (31st March 2021) – DOC] * [ARC/365] + OTC
         */
        let diffInMs;
        if (new Date(LAST_DATE_OF_CLOSURE) > new Date(dateOfClosure)) {
          diffInMs = new Date(LAST_DATE_OF_CLOSURE) - new Date(dateOfClosure);
        } else {
          diffInMs =
            new Date(
              new Date(LAST_DATE_OF_CLOSURE).setFullYear(
                new Date(LAST_DATE_OF_CLOSURE).getFullYear() + 1
              )
            ) - new Date(dateOfClosure);
        }
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return (diffInDays * (parseInt(arc) / 365) + parseInt(otc)).toString();
      },
    },
  },
};

export default calcStub;
