export const WIDGET_WORKSPACE_MODE = {
	WIDGET_VIEW: 'WIDGET_VIEW',
	WIDGET_DATA: 'WIDGET_DATA',
	WIDGET_WORKSPACE: 'WIDGET_WORKSPACE',
	WIDGET_CARD_DATA: 'WIDGET_CARD_DATA',
	CARD_WIDGET_WORKSPACE: 'CARD_WIDGET_WORKSPACE',
}

export const WIDGET_CHART_ID = {
  VERTICAL_BAR_CHART: 1,
  STACKED_BAR_CHART: 2,
  PIE_CHART: 5,
  TABLE_CHART: 6,
  NUMERIC_CHART: 7,
  HORIZONTAL_BAR_CHART: 8,
  SA2_TABLE_CHART: 9,
};

export const WIDGET_TYPE_ID = {
  VERTICAL_BAR_CHART: 1,
  STACKED_BAR_CHART: 2,
  LINE_CHART: 3,
  STACKED_LINE_CHART: 4,
  PIE_CHART: 5,
  TABLE_CHART: 6,
  NUMERIC_CHART: 7,
  HORIZONTAL_BAR_CHART: 8,
};